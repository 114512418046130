// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey:            'AIzaSyDlU2uv0zQHOK-CuhedqM68rgTPU6_wDA0',
    authDomain:        'plantarepj.firebaseapp.com',
    databaseURL:       'https://plantarepj.firebaseio.com',
    projectId:         'plantarepj',
    storageBucket:     'plantarepj.appspot.com',
    messagingSenderId: '212795557455'
  },
  authCookie:        'PlantarEPJFirebaseAuth'
};
