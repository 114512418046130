import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent implements OnInit {

  accepted: boolean;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
  }

  acceptEULA() {
    this.auth.acceptEULA().then(() => {
      this.router.navigateByUrl('/ejp').then(console.log);
    });
  }

}
