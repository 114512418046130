import { Injectable }      from '@angular/core';
import { CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router }                 from '@angular/router';
import { Observable }      from 'rxjs';
import { map, take }       from 'rxjs/operators';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard implements CanActivate {

  constructor(private router: Router, private fb$: FirebaseService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (/Mobi|Android/i.test(navigator.userAgent) && !/iPad/i.test(navigator.userAgent)) {

      return this.fb$.hasOpenJournals().pipe(
        take(1),
        map(hasOpen => {
          if (hasOpen) {
            return this.router.parseUrl('/mobile');
          }
          return true;
        }));
    }
    return true;
  }
}
