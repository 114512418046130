import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardioVascularDisease, Diabetic, GeneralPractitioner, Patient, Sex } from '../journal';
import { HttpClient } from '@angular/common/http';
import { PostCodeCityService } from '../post-code-city.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { FirebaseService } from '../firebase.service';
import { Observable, of } from 'rxjs';
import { firestore } from 'firebase';


@Component({
  selector: 'app-image-dialog',
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NewPatientDialogComponent implements OnInit {

  patient: Patient = {
    name: {
      first: '',
      last: '',
      middle: '',
    },
    sex: Sex.Unspecified,
    diabetic: Diabetic.Unspecified,
    cvd: CardioVascularDisease.Unspecified,
    gp: '',
    address: {
      street: '',
      city: '',
      code: ''
    },
    phone: '',
    mobile: '',
    email: '',
    birthDate: null,
    lastVisit: new firestore.Timestamp(0, 0),
    history: [],
    journal: [],
    consultation: []
  };

  public SexType = Sex;
  public DiabeticType = Diabetic;
  public CardioVascularDiseaseType = CardioVascularDisease;

  nameFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  commFormGroup: FormGroup;
  medicalFormGroup: FormGroup;

  public gps: Observable<GeneralPractitioner[]>;

  public isMobile: boolean;

  constructor(public dialogRef: MatDialogRef<NewPatientDialogComponent>,
              private _fb: FormBuilder,
              private http: HttpClient,
              private zip: PostCodeCityService,
              private fb$: FirebaseService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.gps = of<GeneralPractitioner[]>(null);
    this.isMobile = /Mobi|Android/i.test(navigator.userAgent);
  }

  ngOnInit() {
    this.nameFormGroup = this._fb.group({
      firstName: ['', Validators.required],
      middleName: '',
      lastName: ['', Validators.required],
      birthDate: ['', Validators.required],
      sex: Sex.Unspecified
    });
    this.addressFormGroup = this._fb.group({
      address: '',
      code: '',
      city: ''
    });
    this.commFormGroup = this._fb.group({
      phone: '',
      mobile: '',
      email: ''
    });
    this.medicalFormGroup = this._fb.group( {
      diabetic: Diabetic.Unspecified,
      gp: '',
      cvd: CardioVascularDisease.Unspecified
    });
  }

  findCityFromZip() {
    const code = this.addressFormGroup.get('code').value;
    if (code) {
      this.addressFormGroup.get('city').setValue(this.zip.find(code));
    }
  }

  stepChanged(event: StepperSelectionEvent) {
    if (event.selectedIndex === 3) {
      const postCode = this.addressFormGroup.get('code').value;
      if (postCode) {
        const municipality = this.zip.municipality(postCode);
        this.gps = this.fb$.doctorSearch(municipality);
      }
    }
  }

  onSubmit({ value, valid }: { value: Patient, valid: boolean }) {

    if (valid) {
      this.patient.sex = this.nameFormGroup.get('sex').value;
      this.patient.diabetic = this.medicalFormGroup.get('diabetic').value;
      this.patient.cvd = this.medicalFormGroup.get('cvd').value;
      this.patient.name.first = this.nameFormGroup.get('firstName').value;
      this.patient.name.last = this.nameFormGroup.get('lastName').value;
      this.patient.name.middle = this.nameFormGroup.get('middleName').value;
      this.patient.birthDate = this.nameFormGroup.get('birthDate').value;
      this.patient.address.street = this.addressFormGroup.get('address').value;
      this.patient.address.city = this.addressFormGroup.get('city').value;
      this.patient.address.code = this.addressFormGroup.get('code').value;
      this.patient.phone = this.commFormGroup.get('phone').value;
      this.patient.mobile = this.commFormGroup.get('mobile').value;
      this.patient.email = this.commFormGroup.get('email').value;
      this.patient.gp = this.medicalFormGroup.get('gp').value;
      this.dialogRef.close(this.patient);
    } else {
      this.dialogRef.close(null);
    }
  }

}
