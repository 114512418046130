import { Pipe, PipeTransform } from '@angular/core';
import { firestore } from 'firebase';
import { DatePipe } from '@angular/common';

@Pipe({name: 'fullName'})
export class FullNamePipe implements PipeTransform {

  transform(p: Patient): string {
    let name = p.name.last + ', ' + p.name.first;
    if (p.name.middle) {
      name += ' ' + p.name.middle;
    }
    return name;
  }
}

@Pipe({name: 'fullAddress'})
export class FullAddressPipe implements PipeTransform {

  transform(p: Patient): string {
    if (p.address.street && p.address.code && p.address.city) {
      return p.address.street + ', ' + p.address.code + ' ' + p.address.city;
    }
    if (p.address.street && p.address.code) {
      return p.address.street + ', ' + p.address.code;
    }
    if (p.address.street && p.address.city) {
      return p.address.street + ', ' + p.address.city;
    }
    if (p.address.code && p.address.city) {
      return p.address.code + ' ' + p.address.city;
    }
    if (p.address.code) {
      return p.address.code;
    }
    if (p.address.city) {
      return p.address.city;
    }
    if (p.address.street) {
      return p.address.street;
    }
    return '';
  }
}

@Pipe({name: 'age'})
export class AgePipe implements PipeTransform {

  transform(date: Date): number {
    const now = new Date();
    date = date || now;
    const age = new Date(now.getTime() - date.getTime());
    return age.getFullYear() - 1970;
  }
}

@Pipe({name: 'fireDate'})
export class FirebaseDate implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }
  transform(date: firestore.Timestamp, format = 'd.M.y'): string {
    if (!date) {
      return '-';
    }
    if (date.seconds === 0) {
      return '-';
    }
    return this.datePipe.transform(date.toDate(), format );
  }
}

@Pipe({name: 'doctor'})
export class DoctorPipe implements PipeTransform {

  transform(doc: GeneralPractitioner): string {
    if (!doc) {
      return '';
    }
    let name = doc.lastName + ', ' + doc.firstName;
    if (doc.middleName) {
      name += ' ' + doc.middleName;
    }
    if (doc.office) {
      name += ' - ' + doc.office;
    }
    return name;
  }
}

@Pipe({name: 'gender'})
export class GenderPipe implements PipeTransform {

  transform(value: Sex): string {
    switch (value) {
      case Sex.Unspecified: return 'Uspesifisert';
      case Sex.Male: return 'Mann';
      case Sex.Female: return 'Kvinne';
      case Sex.Transgender: return 'Transkjønnet';
    }
    return 'Uspesifisert';
  }
}

@Pipe({name: 'diabetes'})
export class DiabetesPipe implements  PipeTransform {

  transform(value: Diabetic): string {
    switch (value) {
      case Diabetic.None: return 'Ingen';
      case Diabetic.TypeI: return 'Type 1';
      case Diabetic.TypeII: return 'Type 2';
      case Diabetic.Unspecified: return 'Uspesifisert';
    }
    return 'Uspesifisert';
  }
}

@Pipe({name: 'cardio'})
export class CardioPipe implements PipeTransform {

  transform(value: CardioVascularDisease): string {
    switch (value) {
      case CardioVascularDisease.Unspecified: return 'Uspesifisert';
      case CardioVascularDisease.None: return 'Ingen';
      case CardioVascularDisease.Diagnosed: return 'Ja';
    }
    return 'Uspesifisert';
  }
}

export interface Shortcut {
  id?: string;
  name: string;
  text: string;
  selected?: boolean;
}

export interface Attachment {
  id: string;
  name: string;
  mimeType?: string;
  url?: string;
  icon?: string;
}

export interface Journal {
  id?: string; // Optional unique id of the journal entry
  locked: boolean; // Whether or not it is allowed to update entry
  pid?: string; // Optional unique ref to patient that owns the journal entry
  date: firestore.Timestamp; // Creation date timestamp of the entry
  entry: string; // The actual journal entry formatted in markdown
  attachments: Attachment[]; // List of attachments owned by this journal
  shortcuts: string[]; // Any selected shortcuts used in the journal
}

export interface Consultation {
  id?: string;
  date: firestore.Timestamp;
  note: string;
}

export enum Sex {
  Unspecified,
  Male,
  Female,
  Transgender
}

export enum Diabetic {
  Unspecified,
  None,
  TypeI,
  TypeII
}

export enum CardioVascularDisease {
  Unspecified,
  None,
  Diagnosed
}

export interface GeneralPractitioner {
  id?: string;
  hprNumber: string;
  office?: string;
  lastName: string;
  firstName: string;
  middleName?: string;
  streetAddress: string;
  postBox?: string;
  postalCode: string;
  city: string;
  municipality: string;
}

export interface Patient {
  id?: string;
  name: {
    first: string;
    last: string;
    middle: string;
  };
  sex?: Sex;
  diabetic?: Diabetic;
  cvd?: CardioVascularDisease;
  gp?: string;
  address: {
    street: string;
    city: string;
    code: string;
  };
  phone: string;
  mobile: string;
  email: string;
  birthDate: firestore.Timestamp;
  lastVisit?: firestore.Timestamp;
  history: string[];
  journal: string[];
  consultation: string[];
}

export interface Settings {
  id?: string;
  header: {
    left: string[];
    center: string[];
    right: string[];
    logo?: number;
  };
  footer: {
    left: string[];
    center: string[];
    right: string[];
    logo?: number;
  };
  company: {
    name: string;
    address: {
      street: string;
      city: string;
      code: string;
    }
    phone: string;
    mobile: string;
    email: string;
    web: string;
    org: string;
    logo?: Attachment;
  };
}
