import { BrowserModule }                     from '@angular/platform-browser';
import { BrowserAnimationsModule }           from '@angular/platform-browser/animations';
import { NgModule }                          from '@angular/core';
import { DatePipe }                          from '@angular/common';
import { HttpClientModule }                  from '@angular/common/http';
import { AngularFireModule }                 from '@angular/fire';
import { AngularFirestoreModule }            from '@angular/fire/firestore';
import { AngularFireAuthModule }             from '@angular/fire/auth';
import { ReactiveFormsModule }               from '@angular/forms';
import { MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MarkdownModule }                    from 'ngx-markdown';
import { MaterialModule }                    from './material/material.module';
import { MatPaginatorIntlNO }                from './paginator-no';
import { AppComponent }                      from './app.component';
import { PageNotFoundComponent }             from './page-not-found/page-not-found.component';
import { ElectronicPatientJournalComponent } from './electronic-patient-journal/electronic-patient-journal.component';
import { MarkdownHelpComponent }             from './markdown-help/markdown-help.component';
import { environment }                       from '../environments/environment';
import {
  AgePipe,
  CardioPipe,
  DiabetesPipe,
  FirebaseDate,
  FullAddressPipe,
  FullNamePipe,
  DoctorPipe,
  GenderPipe
} from './journal';
import { SettingsComponent }         from './settings/settings.component';
import { AuthModule }                from './auth.module';
import { AppRoutingModule }          from './app-routing.module';
import { UserLoginComponent }        from './user-login/user-login.component';
import { NewPatientDialogComponent } from './new-patient/new-patient.component';
import { PatientComponent }          from './patient/patient.component';
import { NewJournalComponent }       from './new-journal/new-journal.component';
import { NewHistoryComponent }       from './new-history/new-history.component';
import { ConfirmDialogComponent }    from './confirm-dialog/confirm-dialog.component';
import { NewConsultationComponent }  from './new-consultation/new-consultation.component';
import { InfoDialogComponent }       from './info-dialog/info-dialog.component';
import { IconMorphComponent }        from './icon-morph/icon-morph.component';
import { EulaComponent }             from './eula/eula.component';
import { UpdateJournalComponent }    from './update-journal/update-journal.component';
import { MobileComponent }           from './mobile/mobile.component';
import { LoginFailedComponent }      from './login-failed/login-failed.component';
import { StatisticsComponent }       from './statistics/statistics.component';
import {MatBadgeModule} from '@angular/material/badge';

@NgModule({
  declarations: [
    AgePipe,
    AppComponent,
    CardioPipe,
    ConfirmDialogComponent,
    DiabetesPipe,
    DoctorPipe,
    ElectronicPatientJournalComponent,
    EulaComponent,
    FirebaseDate,
    FullAddressPipe,
    FullNamePipe,
    GenderPipe,
    IconMorphComponent,
    InfoDialogComponent,
    LoginFailedComponent,
    MarkdownHelpComponent,
    MobileComponent,
    NewConsultationComponent,
    NewJournalComponent,
    NewHistoryComponent,
    NewPatientDialogComponent,
    PageNotFoundComponent,
    PatientComponent,
    SettingsComponent,
    StatisticsComponent,
    UpdateJournalComponent,
    UserLoginComponent
  ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AuthModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence({synchronizeTabs: true}),
        AngularFireAuthModule,
        MaterialModule,
        MarkdownModule.forRoot(),
        MatRippleModule,
        MatBadgeModule
    ],
  entryComponents: [
    NewPatientDialogComponent,
    ConfirmDialogComponent,
    InfoDialogComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'no-NO' },
    { provide: MatPaginatorIntl, useValue: new MatPaginatorIntlNO },
    AgePipe,
    CardioPipe,
    DatePipe,
    DiabetesPipe,
    DoctorPipe,
    FirebaseDate,
    FullNamePipe,
    FullAddressPipe,
    GenderPipe
],
  bootstrap: [AppComponent]
})
export class AppModule { }
