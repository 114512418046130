import { Component, OnInit,
  ViewChild, OnDestroy,
  AfterViewInit }                  from '@angular/core';
import { Router }                  from '@angular/router';
import {
  AgePipe, FullAddressPipe,
  FullNamePipe, Journal,
  Patient
} from '../journal';
import { MatDialog }                 from '@angular/material/dialog';
import { MatSort }                   from '@angular/material/sort';
import { MatTableDataSource }        from '@angular/material/table';
import { MatPaginator }              from '@angular/material/paginator';
import { Observable, Subscription }  from 'rxjs';
import { take }                      from 'rxjs/operators';
import { FirebaseService }           from '../firebase.service';
import { NewPatientDialogComponent } from '../new-patient/new-patient.component';
import { BusyService }               from '../busy.service';
import { environment }               from '../../environments/environment';

class IncompleteJournal {
  constructor(public patient: Patient, public journal: Journal) {  }
}

@Component({
  selector: 'app-electronic-patient-journal',
  templateUrl: './electronic-patient-journal.component.html',
  styleUrls: ['./electronic-patient-journal.component.scss']
})
export class ElectronicPatientJournalComponent implements OnInit, OnDestroy, AfterViewInit {

  private _subscription: Subscription;

  patientDataSource: MatTableDataSource<Patient>;
  displayedColumns = ['fullName', 'birthDate', 'lastVisit' ];

  incompleteTable: MatTableDataSource<IncompleteJournal>;
  incompleteDisplay = ['fullName', 'date'];

  @ViewChild(MatPaginator) set _paginator(paginator: MatPaginator) {
    this.patientDataSource.paginator = paginator; } paginator:  MatPaginator;
  @ViewChild(MatSort) set _sorter(sort: MatSort) {
    this.patientDataSource.sort = sort;
  } sort: MatSort;

  patient$: Observable<Patient[]>;

  firebaseAvailable: boolean;

  constructor(private router: Router, private firebase$: FirebaseService, private agePipe: AgePipe, public busy: BusyService,
              private namePipe: FullNamePipe, private addressPipe: FullAddressPipe, public dialog: MatDialog) {
    this._subscription = new Subscription();
    this.busy.working = true;
    this.firebaseAvailable = false;
  }

  ngOnInit() {

    this.busy.working = true;

    this.incompleteTable = new MatTableDataSource<IncompleteJournal>();

    this.patientDataSource = new MatTableDataSource<Patient>();
    this.patientDataSource.sortingDataAccessor = this.patientSorting.bind(this);
    this.patientDataSource.paginator = this.paginator;
    this.patientDataSource.filterPredicate = (data, filter): boolean => {
      const name = this.namePipe.transform(data);
      const address = this.addressPipe.transform(data);
      const age = this.agePipe.transform(data.birthDate.toDate());
      const birthYear = data.birthDate.toDate().getFullYear().toString();
      return name.toLowerCase().includes(filter) ||
        address.toLowerCase().includes(filter) ||
        age.toString().includes(filter) || birthYear === filter;
    };
    this.firebase$.available.subscribe(available => {
      if (available) {
        this.patient$ = this.firebase$.patients;
        this._subscription.add(this.patient$.subscribe(patients => {
          console.log('* got patients *');
          if (patients) {
            this.patientDataSource.data = patients;
            this.firebaseAvailable = true;
          }
          this.busy.working = false;
        }));
        this.firebase$.unlockedJournals().subscribe((incomplete: Journal[]) => {
          Promise.all(incomplete.map((j: Journal) => this.firebase$.getPatient(j.pid) )).then(results => {
            this.incompleteTable.data = results.map((p, i) => {
              return new IncompleteJournal(p, incomplete[i]);
            });
          });
        });
      }
    });

    /* On successful login delete the cookie token */
    localStorage.removeItem(environment.authCookie);
  }

  ngAfterViewInit(): void {
    this.patientDataSource.paginator = this.paginator;
    this.patientDataSource.sort = this.sort;
  }

  patientSorting(data: Patient, sortHeaderId: string): string | number {
    switch (sortHeaderId) {
      case 'fullName': return this.namePipe.transform(data);
      case 'birthDate': return this.agePipe.transform(data.birthDate.toDate());
      case 'age': return this.agePipe.transform(data.birthDate.toDate());
      case 'mobile': return data.mobile;
      case 'email': return data.email;
      case 'lastVisit': return data.lastVisit ? data.lastVisit.seconds : 0;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  editJournal(row: any) {
    this.router.navigate(['/update-journal', {id: row.journal.id, pid: row.patient.id }], {skipLocationChange: true}).then(() => {
      console.log('route navigate complete');
    });

  }

  applyFilter(filterValue: string) {
    this.patientDataSource.filter = filterValue.trim().toLowerCase();

    if (this.patientDataSource.paginator) {
      this.patientDataSource.paginator.firstPage();
    }
  }
  newPatientDialog(): void {
    const dialogRef = this.dialog.open(NewPatientDialogComponent);
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.firebase$.addPatient(result as Patient);
      }
    });
  }

  edit(patient: any) {
    this.router.navigate(['/journal', patient.id, {tab: '0'}], {skipLocationChange: true}).then(() => {
      console.log('route navigate complete');
    });
  }

}

