import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService, User} from './auth.service';
import { BusyService } from './busy.service';
import {Observable} from 'rxjs';
import {FirebaseService} from './firebase.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public title: string;
  public avatar: any;
  public user: string;
  public pendingCount = 0;

  private unlockedJournals: Observable<number>;


  constructor(private router: Router, private activatedRoute: ActivatedRoute, iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer, public auth: AuthService, public busy: BusyService,
              private fb$: FirebaseService) {
    this.avatar = '';
    this.user = '';
    iconRegistry.addSvgIcon('plantar',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/plantar.svg'));
    iconRegistry.addSvgIcon('plantar-bw',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/plantar-bw.svg'));
    iconRegistry.addSvgIcon('firebase',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/firebase.svg'));
    iconRegistry.addSvgIcon('firebase-sm',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/Firebase_Logo_Logomark.svg'));
    iconRegistry.addSvgIcon('facebook',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/facebook.svg'));
    iconRegistry.addSvgIcon('google',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/btn_google_light_normal_ios.svg'));
    iconRegistry.addSvgIcon('logout',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/logout.svg'));
    iconRegistry.addSvgIcon('pdf',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/file-pdf.svg'));
    iconRegistry.addSvgIcon('portrait',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/portrait.svg'));
    iconRegistry.addSvgIcon('diabetes',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/diabetes.svg'));
    iconRegistry.addSvgIcon('diabetes-1',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/diabetes-1.svg'));
    iconRegistry.addSvgIcon('diabetes-2',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/diabetes-2.svg'));
    iconRegistry.addSvgIcon('heart',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/heart.svg'));
    iconRegistry.addSvgIcon('male',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/face.svg'));
    iconRegistry.addSvgIcon('female',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/face-woman.svg'));
    iconRegistry.addSvgIcon('youngest',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/youngest.svg'));
    iconRegistry.addSvgIcon('oldest',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/oldest.svg'));
  }

  ngOnInit() {

    this.activatedRoute.data.subscribe(data => {
      console.log('Activated route data:', data);
    });

    this.auth.user.subscribe((user: User) => {
      if (user) {
        this.user = user.displayName;
        this.avatar = {
          'background-image': `url(${user.photoURL}`,
          'width': '48px',
          'height': '48px',
          'background-size': 'cover',
          'background-position': 'center',
          'border-radius': '50%',
          'border': '1px solid rgba(0,0,0,0.25)'
        };
        this.router.navigate(['/ejp']).then(() => {
          console.log('Logged in');
        });

        this.unlockedJournals = this.fb$.pendingJournalsCount();
        this.unlockedJournals.subscribe(n => this.pendingCount = n);
      } else {
        this.user = '';
        this.avatar = '';
      }
    });
  }

  actionLogout(): void {
    this.auth.signOut();
  }
}

