import { Injectable }     from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree, Router, CanLoad, Route, CanActivateChild
} from '@angular/router';
import { Observable }     from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService }    from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.auth.user.pipe(
      take(1),
      map(user => user.admin),
      tap(admin => {
        if (admin) {
          console.log('* WARNING! Your Admin *');
        }
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user.admin),
      tap(admin => {
        if (admin) {
          console.log('* WARNING! Your Admin *');
        }
      })
    );
  }

 }
