
import {
  Component,
  OnDestroy,
  OnInit, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgForm }                       from '@angular/forms';
import { Attachment, Journal, Patient } from '../journal';
import { ActivatedRoute, Router }       from '@angular/router';
import { FirebaseService, Upload }      from '../firebase.service';
import { Observable }                   from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { firestore }                    from 'firebase';
import { BusyService }                  from '../busy.service';
import { ConfirmDialogComponent }       from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-new-history',
  templateUrl: './new-history.component.html',
  styleUrls: ['./new-history.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class NewHistoryComponent implements OnInit, OnDestroy {

  public attachments: any[] = [];
  public fileProgress: number[] = [];
  public lockJournal: boolean;

  patient$: Observable<Patient[]>;
  @ViewChild(NgForm) form: NgForm;

  public patient: Patient;
  public composing: boolean;
  public journal: Journal;
  public uploadProgress: number;
  public uploadIcon: string;
  public uploadColor: string;
  public date: Date = new Date();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private firebase$: FirebaseService,
              public dialog: MatDialog,
              public busy: BusyService) {

    this.composing = false;
    this.patient = null;
    this.journal = this.newJournalEntry();
    this.patient$ = this.firebase$.patients;
    this.uploadProgress = 0;
    this.uploadIcon = 'save_alt';
    this.uploadColor = 'primary';
    this.lockJournal = true;
  }

  ngOnInit() {
    this.firebase$.getPatient(this.route.snapshot.paramMap.get('id')).then(p => {
      this.patient = p;
    });
  }

  ngOnDestroy() {
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.attachments.length === 0 && this.form.pristine) {
      return true;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: { ok: 'warn', title: 'Lagre endringer?', message: `Du har gjort endringer uten å lagre. Forkast endringer?` }
    });

    return dialogRef.afterClosed();
  }

  onFileChange(event) {
    for (let i = 0; i < event.target.files.length; ++i) {
      if (this.attachments.findIndex(x => x.file === event.target.files[i]) === -1) {
        const attachment = {
          file: event.target.files[i],
          name: event.target.files[i].name,
          type: event.target.files[i].type,
          url: null,
          icon: 'portrait'
        };

        if (attachment.type.match(/application\/pdf/ig)) {
          attachment.icon = 'pdf';
        }
        this.attachments.push(attachment);
      }
    }
  }

  showAttachment(a: any) {
    const url = window.URL.createObjectURL(a.file);
    window.open(url, '_blank');
  }

  deleteAttachment(a: any) {
    const i = this.attachments.findIndex(x => x === a);
    if (i !== -1) {
      this.attachments.splice(i, 1);
    }
  }

  /**
   * Add new journal entry
   */
  newJournalEntry(): Journal {
    return {
      attachments: [],
      locked: true,
      date: firestore.Timestamp.now(),
      entry: '',
      shortcuts: []
    } as Journal;
  }

  goToJournal(): void {
    this.router.navigate(['/journal', this.patient.id, { tab: '@'}], {skipLocationChange: true}).then(() => {
      console.log('route navigate complete');
    });
  }

  cancelNewJournalEntry(): void {
    this.goToJournal();
  }

  pushUpload(refId, file, i): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.firebase$.pushUpload(new Upload(file, this.patient.id, refId)).subscribe(progress => {
        this.fileProgress[i] = progress;
        this.uploadProgress = this.fileProgress.reduce((a, c) => a + c) / this.fileProgress.length;
      }, () => {
        reject();
      }, () => {
        resolve();
      });
    });
  }

  storeNewJournalEntry(): void {

    if (this.busy.working) {
      return;
    }

    const journal = {
      pid: this.patient.id,
      locked: this.lockJournal,
      date: firestore.Timestamp.fromDate(this.date),
      entry: this.journal.entry,
      attachments: this.attachments.map(f => ({ name: f.name, mimeType: f.type } as Attachment)),
      shortcuts: [].concat(this.journal.shortcuts)
    } as Journal;

    this.busy.working = true;
    this.uploadIcon = 'cloud_upload';
    this.uploadProgress = 0;
    this.firebase$.addHistory(this.patient, journal).then(id => {
      const count = this.attachments.length;
      this.fileProgress = new Array(count);
      this.fileProgress.fill(0);

      Promise.all(this.attachments.map((a, i) => this.pushUpload(id, a.file, i))).then(() => {
        this.busy.working = false;
        this.uploadIcon = 'check';
        this.uploadColor = 'accent';
        setTimeout(() => {
          this.attachments.splice(0);
          this.uploadIcon = 'save_alt';
          this.uploadColor = 'primary';
          this.journal.entry = '';
          this.journal.attachments.splice(0);
          this.uploadProgress = 0;
          this.form.resetForm();
          this.goToJournal();
        }, 1000);
      }).catch(() => {
        /* Upload of some or all the files failed */
      });

      if (count === 0) {
        this.busy.working = false;
        this.journal.entry = '';
        this.journal.attachments.splice(0);
        this.form.resetForm();
        this.goToJournal();
      }

    });
  }

}
