import { Injectable }                 from '@angular/core';
import { FirebaseService }            from './firebase.service';
import {Consultation, Journal, Patient, Settings} from './journal';
import * as moment                    from 'moment';
import 'moment/locale/nb';
import * as pdfMake                   from 'pdfmake/build/pdfmake.js';
import * as pdfFonts                  from 'pdfmake/build/vfs_fonts.js';
import * as marked                    from 'marked/lib/marked.js';
import { HttpClient }                 from '@angular/common/http';
import {MarkdownRenderer} from './markdown-renderer';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class HeaderAndFooter {
  header: any;
  footer: any;
  images: any;
}

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(private fb$: FirebaseService, private http: HttpClient) {
    this.headerAndFooter = null;
    this.document = {
      content: [],
      defaultStyle: {
        columnGap: 20
      },
      pageSize: 'A4',
      pageMargins: [60, 120, 60, 120],
      styles: {
        display4: {
          fontSize: 72,
          margin: [10, 10]
        },
        display3: {
          fontSize: 48,
          margin: [10, 10]
        },
        display2: {
          fontSize: 32,
          margin: [10, 10]
        },
        display1: {
          fontSize: 24,
          margin: [10, 10]
        },
        headline: {
          fontSize: 18,
          margin: [8, 8]
        },
        title: {
          fontSize: 14,
          margin: [6, 6],
          bold: true
        },
        subhead: {
          fontSize: 12,
          margin: [5, 5]
        },
        body1: {
          fontSize: 10,
          margin: [4, 4]
        },
        body2: {
          fontSize: 10,
          margin: [4, 4],
          bold: true
        },
        caption: {
          fontSize: 8,
          margin: [2, 2]
        }
      }
    };
    this.headingMap = ['body', 'display3', 'display2', 'display1', 'headline', 'title', 'subhead'];
  }

  document: any;
  headingMap: string[];
  private headerAndFooter: HeaderAndFooter;

  private static shortcutHeading(title: string): any {
    const content = [];
    content.push({style: 'subhead', text: `Utført behandling: ${title}`});
    return content;
  }

  private static personal(patient: Patient): any {
    const content = [];
    const birthDate = moment(patient.birthDate.toDate()).format('l');
    content.push({style: 'subhead', text: 'Pasient'});
    content.push({columns: [
        {stack: [
            {style: 'body2', text: `${patient.name.last}, ${patient.name.first} ${patient.name.middle}`},
            {style: 'body1', text: `${patient.address.street}`},
            {style: 'body1', text: `${patient.address.code} ${patient.address.city}`}
          ]
        },
        {style: 'body2', text: birthDate }
      ]});
    return content;
  }

  private static markdownToStyles(md: string): any {

    const content = marked.lexer(md);
    const renderer = new MarkdownRenderer();
    const parsed = marked.parser(content, {renderer: renderer});
    return renderer.getContents(parsed);

  }

  getHeaderAndFooters(): Promise<any> {
    return new Promise<any>((resolve) => {
      if (this.headerAndFooter) {
        resolve(this.headerAndFooter);
        return;
      }
      this.fb$.settings.subscribe(value => {
        const settings = value as Settings;
        if (settings) {
          console.dir('Got settings:', settings);
          this.headerAndFooter = new HeaderAndFooter();
          this.headerAndFooter.footer = {
            margin: 40,
            columns: [
              {
                width: '*', alignment: 'left', style: 'caption',
                stack: settings.footer.left
              },
              {
                width: '*', alignment: 'left', style: 'caption',
                stack: settings.footer.center
              },
              {
                width: '*', alignment: 'right', style: 'caption',
                stack: settings.footer.right
              }
            ]
          };

          this.headerAndFooter.header = {
            margin: 40,
            columns: [
              {
                width: '*', alignment: 'left', style: 'caption',
                stack: settings.header.left
              },
              {
                width: '*', alignment: 'left', style: 'caption',
                stack: settings.header.center
              },
              {
                width: '*', alignment: 'right', style: 'caption',
                stack: settings.header.right
              }
            ]
          };

          if (settings.company.logo && settings.company.logo.url) {
            this.http.get(settings.company.logo.url, {responseType: 'blob'}).subscribe(data => {
              console.log(data);
              const reader  = new FileReader();

              reader.addEventListener('load', () => {
                this.headerAndFooter.images = {
                  logo: reader.result
                };
                const imageLeft = { width: '*', alignment: 'left', image: 'logo', fit: [64, 64] };
                const imageCenter = { width: '*', alignment: 'center', image: 'logo', fit: [64, 64] };
                const imageRight = { width: '*', alignment: 'right', image: 'logo', fit: [64, 64] };
                if (settings.footer.logo === 1) {
                  this.headerAndFooter.footer.columns[0] = imageLeft;
                }
                if (settings.footer.logo === 2) {
                  this.headerAndFooter.footer.columns[1] = imageCenter;
                }
                if (settings.footer.logo === 3) {
                  this.headerAndFooter.footer.columns[2] = imageRight;
                }
                if (settings.header.logo === 1) {
                  this.headerAndFooter.header.columns[0] = imageLeft;
                }
                if (settings.header.logo === 2) {
                  this.headerAndFooter.header.columns[1] = imageCenter;
                }
                if (settings.header.logo === 3) {
                  this.headerAndFooter.header.columns[2] = imageRight;
                }
                resolve(this.headerAndFooter);
              }, false);

              if (data) {
                reader.readAsDataURL(data);
              }
            });
          }
        }
      });

    });
  }

  createDocumentFromConsultation(patient: Patient, consultation: Consultation): void {
    this.getHeaderAndFooters().then(headerAndFooter => {
      this.document.footer = headerAndFooter.footer;
      this.document.header = headerAndFooter.header;
      this.document.images = headerAndFooter.images;
      moment.locale('no-NO');
      this.document.content = [];
      this.document.content.push(PrintService.personal(patient));
      this.document.content.push(PrintService.markdownToStyles(consultation.note));
      const doc = pdfMake.createPdf(this.document);
      try {
        doc.open();
      } catch (e) {
        console.log('Failed to open PDF. Trying download instead.', e);
        try {
          doc.download();
        } catch (e) {
          console.log('all attempts in opening PDF failed. Sorry...');
        }
      }
    });
  }

  createDocumentFromJournal(patient: Patient, journal: Journal): void {

    this.getHeaderAndFooters().then(headerAndFooter => {
      this.document.footer = headerAndFooter.footer;
      this.document.header = headerAndFooter.header;
      this.document.images = headerAndFooter.images;
      moment.locale('no-NO');
      this.document.content = [];
      this.document.content.push(PrintService.personal(patient));
      this.document.content.push(PrintService.markdownToStyles(journal.entry));
      Promise.all(journal.shortcuts.map(s => this.fb$.getShortCut(s))).then(results => {
        results.forEach(shortcut => {
          this.document.content.push(PrintService.shortcutHeading(shortcut.name));
          this.document.content.push(PrintService.markdownToStyles(shortcut.text));
        });
        const doc = pdfMake.createPdf(this.document);
        try {
          doc.open();
        } catch (e) {
          console.log('Failed to open PDF. Trying download instead.', e);
          try {
            doc.download();
          } catch (e) {
            console.log('all attempts in opening PDF failed. Sorry...');
          }
        }
      });
    });
  }

}
