import { Injectable }                  from '@angular/core';
import { CardioVascularDisease,
  Consultation,
  Diabetic,
  GeneralPractitioner,
  Journal,
  Patient,
  Settings,
  Sex,
  Shortcut}                            from './journal';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take }        from 'rxjs/operators';
import { AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument}            from '@angular/fire/firestore';
import {firestore, storage} from 'firebase';
import { AuthService, User }           from './auth.service';
import DocumentData = firebase.firestore.DocumentData;

export class Upload {
  patient: string;
  journal: string;
  file: File;
  name: string;
  url: string;
  progress: number;
  id: string;

  /**
   * Create an upload request
   * @param {File} file The file
   * @param {string} patient The ID ref of the patient that owns the journal
   * @param {string} journal The ID ref of the journal that owns the attachment
   */
  constructor(file: File, patient: string, journal: string) {
    this.file = file;
    this.patient = patient;
    this.journal = journal;
    this.progress = 0;
    this.url = '';
  }
}

export class UploadLogo {
  id: string;
  file: File;
  progress: number;
  url: string;
  name: string;

  /**
   * Create a logo upload request
   * @param {File} file The file to upload
   * @param {String} id The settings id that owns the logo
   */
  constructor(file: File, id: string) {
    this.file = file;
    this.id = id;
    this.progress = 0;
    this.name = this.file.name;
    this.url = '';
  }
}

@Injectable({providedIn: 'root'})
export class FirebaseService {

  private patientCollectionRef: AngularFirestoreCollection<Patient>;
  private journalCollectionRef: AngularFirestoreCollection<Journal>;
  private journal$: Observable<Journal[]>;
  private shortcutCollectionRef: AngularFirestoreCollection<Shortcut>;
  private shortcut$: Observable<Shortcut[]>;
  private settings$: Observable<Settings>;
  private settingsDocRef: AngularFirestoreDocument<Settings>;
  private consultationCollectionsRef: AngularFirestoreCollection<Consultation>;
  private consultation$: Observable<Consultation[]>;
  private gpsCollectionRef: AngularFirestoreCollection<GeneralPractitioner>;
  private gps$: Observable<GeneralPractitioner[]>;
  private user: User;
  private storageRef: any;
  private mediaBasePath = '/media';

  available: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private fireStore: AngularFirestore, private auth: AuthService) {

    this.storageRef = storage().ref();

    this.auth.user.subscribe(user => {
      console.log('FirebaseService user', user);
      this.user = user;
      if (this.user) {
        this.mediaBasePath = `/media/${this.user.uid}`;
        this.getPatients();
        this.getShortcuts();
        this.getJournals();
        this.getSettings();
        this.getConsultations();
        this.getDoctors();
      }
    });

  }

  pushUpload(upload: Upload): Observable<number> {
    const progress = new BehaviorSubject<number>(0);
    const uploadTask = this.storageRef.child(`${this.mediaBasePath}/${upload.patient}/${upload.journal}/${upload.file.name}`)
      .put(upload.file);
    uploadTask.on(storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // upload in progress
        upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress.next(upload.progress);
      },
      (error) => {
        // upload failed
        console.log(error);
        progress.complete();
      },
      () => {
        // upload success
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          upload.url = downloadURL;
          // console.log('url', upload.url);
        });
        // upload.url = uploadTask.snapshot.downloadURL;
        upload.name = upload.file.name;
        // console.log('name', upload.name);
        progress.next(100);
        progress.complete();
        // TODO this.saveFileData(upload)
      }
    );
    return progress;
  }

  uploadLogo(upload: UploadLogo): Observable<number> {
    const progress = new BehaviorSubject<number>(0);
    const uploadTask = this.storageRef.child(`${this.mediaBasePath}/${upload.id}/${upload.file.name}`).put(upload.file);
    uploadTask.on(storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // upload in progress
        upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress.next(upload.progress);
      },
      (error) => {
        // upload failed
        console.log(error);
        progress.complete();
      },
      () => {
        // upload success
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          upload.url = downloadURL;
          upload.name = upload.file.name;
          console.log('name', upload.name);
          progress.next(100);
          progress.complete();
        });
      }
    );
    return progress;
  }


  getAttachmentURL(patient: Patient, journal: Journal, name: string): Promise<any> {
    // Create a reference to the file we want to download
    // console.dir(patient);
    // console.dir(journal);
    // console.dir(name);
    return this.storageRef.child(`${this.mediaBasePath}/${patient.id}/${journal.id}/${name}`).getDownloadURL();
  }

  /**
   * Get all the journals for the current user
   */
  private getJournals(): void {
    this.journalCollectionRef = this.fireStore.collection(this.user.uid).doc('journals').collection('journal');
    this.journal$ = this.journalCollectionRef.snapshotChanges().pipe(map(actions => {
      return actions.map(action => {
        const data = action.payload.doc.data() as Journal;
        const id = action.payload.doc.id;
        data.id = id;
        return {id, ...data};
      });
    }));
  }

  /**
   * Get a list of incomplete journals
   * @returns {Observable<Journal[]>}
   */
  unlockedJournals(): Observable<Journal[]> | Observable<DocumentData[]> {
    return this.fireStore.collection(this.user.uid).doc('journals').collection('journal', ref =>
      ref.where('locked', '==', false)).snapshotChanges().pipe(map(actions => {
      return actions.map(action => {
        const data = action.payload.doc.data() as Journal;
        const id = action.payload.doc.id;
        data.id = id;
        return {id, ...data};
      });
    }));
  }

  /**
   * Get the number of pending/incomplete journals
   */
  pendingJournalsCount(): Observable<number> {
    return this.fireStore.collection(this.user.uid).doc('journals').collection('journal', ref =>
      ref.where('locked', '==', false)).snapshotChanges().pipe(map(actions => {
      return actions.length;
    }));
  }

  /**
   * Return a boolean observable which is true when there are journals not locked
   * @returns {Observable<boolean>}
   */
  hasOpenJournals(): Observable<boolean> {

    return this.auth.user.pipe(take(1),
      switchMap(user => {
        if (!!user) {
          return this.fireStore.collection(user.uid).doc('journals').collection('journal', ref =>
            ref.where('locked', '==', false)).snapshotChanges();
        }
      }),
      map(journals => {
        return journals.length > 0;
      }));
  }


  /**
   * Get the list of journals
   * @returns {Observable<Journal[]>}
   */
  get journals(): Observable<Journal[]> {
    return this.journal$;
  }

  getJournal(id: string): Promise<Journal> {
    return new Promise<Journal>((resolve, reject) => {
      const journal = this.fireStore.collection(this.user.uid).doc('journals').collection('journal').doc(id);
      if (journal) {
        journal.ref.get().then(s => {
          resolve({id, ...s.data()} as Journal);
        }).catch(err => {
          reject(err);
        });
      } else {
        reject('Document with id ' + id + ' not found.');
      }
    });
  }

  private getShortcuts(): void {
    this.shortcutCollectionRef = this.fireStore.collection(this.user.uid).doc('shortcuts').collection('shortcut');
    this.shortcut$ = this.shortcutCollectionRef.snapshotChanges().pipe(map(actions => {
      return actions.map(action => {
        const data = action.payload.doc.data() as Shortcut;
        const id = action.payload.doc.id;
        data.id = id;
        return {id, ...data};
      });
    }));
  }

  /**
   * Retrieve the list of predefined shortcuts
   * @returns {Observable<Shortcut[]>}
   */
  get shortcuts(): Observable<Shortcut[]> {
    return this.shortcut$;
  }

  /**
   * Add a new shortcut
   * @param {Shortcut} shortcut The shortcut content
   */
  addShortcut(shortcut: Shortcut): Promise<Shortcut> {
    return new Promise<Shortcut>((resolve, reject) => {
      this.shortcutCollectionRef.add(shortcut).then(docRef => {
        console.log('Document written with ID: ' + docRef.id);
        shortcut.id = docRef.id;
        resolve(shortcut);
      }).catch(err => {
        console.log('Could not write to database');
        console.log(err);
        reject(err);
      });
    });
  }

  /**
   * Get the shortcut contents for the specified ID
   * @param {string} id
   */
  getShortCut(id: string): Promise<Shortcut> {
    return new Promise<Shortcut>((resolve, reject) => {
      const shortcut = this.fireStore.collection(this.user.uid).doc('shortcuts').collection('shortcut').doc(id);
      if (shortcut) {
        shortcut.ref.get().then(s => {
          resolve({id, ...s.data()} as Shortcut);
        }).catch(err => {
          reject(err);
        });
      } else {
        reject('Document with id ' + id + ' not found.');
      }
    });
  }

  /**
   * Delete the shortcut. The shortcut can only be deleted if it is not used in any journals
   * @param {Shortcut} shortcut
   * @returns {Promise<any>}
   */
  deleteShortcut(shortcut: Shortcut): Promise<any> {
    return this.shortcutCollectionRef.doc(shortcut.id).delete();
  }

  /**
   * Get a specific consultation document from the unique id
   * @param {String} id The unique ID of the consultation document
   */
  getConsultation(id: string): Promise<Consultation> {
    return new Promise<Consultation>((resolve, reject) => {
      const consultation = this.fireStore.collection(this.user.uid).doc('consultations').collection('consultation').doc(id);
      if (consultation) {
        consultation.ref.get().then(s => {
          resolve({id, ...s.data()} as Consultation);
        }).catch(err => {
          reject(err);
        });
      } else {
        reject('Document with id ' + id + ' not found.');
      }
    });
  }

  /**
   * Get all the consultations from the current user
   */
  private getConsultations(): void {
    this.consultationCollectionsRef = this.fireStore.collection(this.user.uid).doc('consultations').collection('consultation');
    this.consultationCollectionsRef.valueChanges().subscribe(consultations => {
      console.log('Collection changes', consultations);
    });
    this.consultation$ = this.consultationCollectionsRef.snapshotChanges().pipe(map(actions => {
      return actions.map(action => {
        const data = action.payload.doc.data() as Consultation;
        const id = action.payload.doc.id;
        data.id = id;
        return {id, ...data};
      });
    }));
  }

  get consultations(): Observable<Consultation[]> {
    return this.consultation$;
  }

  private getPatients(): void {

    this.patientCollectionRef = this.fireStore.collection(this.user.uid).doc('patients').collection('patient');
    this.available.next(true);
  }

  private getDoctors(): void {

    this.gpsCollectionRef = this.fireStore.collection('gps');
    this.gps$ = this.gpsCollectionRef.snapshotChanges().pipe(map(actions =>
      actions.map(action => {
        const data = action.payload.doc.data() as GeneralPractitioner;
        const id = action.payload.doc.id;
        data.id = id;
        return {id, ...data};
      })
    ));
  }

  doctorSearch(municipality: string): Observable<GeneralPractitioner[]> {
    return this.fireStore.collection('gps', ref =>
      ref.orderBy('lastName').orderBy('firstName')
        .where('municipality', '==', municipality))
        .snapshotChanges().pipe(map(actions => {
        return actions.map(action => {
          const data = action.payload.doc.data() as GeneralPractitioner;
          const id = action.payload.doc.id;
          data.id = id;
          return {id, ...data};
        });
      }));
  }



  /**
   * The list of patients as Observable
   * @returns {Observable<Patient[]>}
   */
  get patients(): Observable<Patient[]> {
    return this.patientCollectionRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Patient;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  /**
   * Get the patient record from the id
   * @param {string} id The id of the patient
   */
  getPatient(id: string): Promise<Patient> {
    return new Promise<Patient>((resolve, reject) => {
      const patient = this.fireStore.collection(this.user.uid).doc('patients').collection('patient').doc(id);
      if (patient) {
        patient.ref.get().then(p => {
          const data = p.data();
          if (data) {
            const result = {id, ...data} as Patient;
            result.sex = result.sex || Sex.Unspecified;
            result.diabetic = result.diabetic || Diabetic.Unspecified;
            result.gp = result.gp || 'none';
            result.cvd = result.cvd || CardioVascularDisease.Unspecified;
            result.lastVisit = result.lastVisit || new firestore.Timestamp(0, 0);
            resolve(result);
          } else {
            reject('No data stored for specified id');
          }
        }).catch(err => {
          reject(err);
        });
      } else {
        reject('Document with id ' + id + ' not found.');
      }
    });
  }

  /**
   * Get the GP with the specified ID (hprNumber)
   * @param {string} hprNumber Unique identifier for the GP
   * @returns {Promise<GeneralPractitioner>}
   */
  getDoctor(hprNumber: string): Promise<GeneralPractitioner> {
    return new Promise<GeneralPractitioner>((resolve, reject) => {
      const doctor = this.fireStore.collection('gps').doc(hprNumber);
      if (doctor) {
        doctor.ref.get().then(doc => {
          const data = doc.data();
          if (data) {
            const result = data as GeneralPractitioner;
            resolve(result);
          } else {
            reject('No doctor with hprNumber ' + hprNumber + ' was found');
          }
        }).catch(reject);
      } else {
        reject('Doctor with hprNumber ' + hprNumber + ' not found');
      }
    });
  }

  /**
   * Batch write the list of general practitioners
   * @param {GeneralPractitioner[]} list The list of GPs
   */
  async addDoctors(list: GeneralPractitioner[]) {

    while (list.length) {

      const subList = list.splice(0, 100);

      const batch = this.fireStore.firestore.batch();
      for (let i = 0, j = subList.length; i < j; i++) {
        const ref = this.gpsCollectionRef.doc(subList[i].hprNumber).ref;
        batch.set(ref, { municipality: subList[i].municipality }, {merge: true});
      }
      // Commit the batch
      await batch.commit();
    }
  }


  /**
   * Add a new patient record to the database
   * @param {Patient} patient The patient to add
   */
  addPatient(patient: Patient) {
    this.patientCollectionRef.add(patient).then(docRef => {
      console.log('Document written with ID: ' + docRef.id);
    }).catch(err => {
      console.log('Could not write to database');
      console.log(err);
    });
  }

  /**
   * Update the patient with new or changed properties
   * @param {Patient} patient The patient to update
   * @returns {Promise<string>}
   */
  updatePatient(patient: Patient): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      console.log(patient);
      this.patientCollectionRef.doc(patient.id).update(patient).then(() => {
        console.log('Document with ID: ' + patient.id + ' updated.');
        resolve(patient.id);
      }).catch(err => {
        console.log('Could not write to database. Error: ', err);
        reject(err);
      });
    });
  }

  /**
   * Update the journal with the specified data
   * @param {Journal} journal
   * @returns {Promise<string>}
   */
  updateJournal(journal: Journal): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.journalCollectionRef.doc(journal.id).update(journal).then(() => {
        resolve(journal.id);
      }).catch(reject);
    });
  }

  /**
   * Delete an attachment image from the media storage
   * @param {string} patient The id of the patient
   * @param {string} journal The id of the journal
   * @param {string} name The attachment name
   */
  deleteAttachment(patient, journal, name) {
    return this.storageRef.child(`${this.mediaBasePath}/${patient}/${journal}/${name}`).delete();
  }

  private deleteAttachments(patient: Patient) {
    return new Promise((resolve) => {
      const tasks = [
        Promise.all(patient.journal.map(id => this.getJournal(id))).then(journals => {
          journals.forEach(journal => {
            Promise.all(journal.attachments.map(a => this.deleteAttachment(patient.id, journal.id, a.name))).then(() => {
              console.log('Deleted journal attachments');
            });
          });
        }),
        Promise.all(patient.history.map(id => this.getJournal(id))).then(journals => {
          journals.forEach(journal => {
            Promise.all(journal.attachments.map(a => this.deleteAttachment(patient.id, journal.id, a.name))).then(() => {
              console.log('Deleted history attachments');
            });
          });
        })
      ];
      Promise.all(tasks).then(resolve);
    });
  }

  /**
   * Delete the specified patient and all records owned from database
   * @param {Patient} patient The patient to delete
   */
  deletePatient(patient: Patient) {
    return new Promise<Boolean>((resolve) => {

      this.deleteAttachments(patient).then(() => {
        this.patientCollectionRef.doc(patient.id).delete().then(() => {
          console.log('Patient with ID: ' + patient.id + ' was deleted.');
          Promise.all(patient.journal.map(id => this.journalCollectionRef.doc(id).delete())).then(() => {
            console.log('Deleted journal entries');
            Promise.all(patient.history.map(id => this.journalCollectionRef.doc(id).delete())).then(() => {
              console.log('Deleted history entries');
              Promise.all(patient.consultation.map(id => this.consultationCollectionsRef.doc(id).delete())).then(() => {
                console.log('Deleted consultations');
                resolve(true);
              });
            });
          });
        }).catch(err => {
          console.log('Could not delete from database. Error: ', err);
          resolve(false);
        });
      });

    });
  }

  /**
   * Add a new journal entry to the specified patient
   * @param {Patient} patient The patient that owns the journal
   * @param {Journal} journal The new journal
   * @returns {Promise<string>}
   */
  addJournal(patient: Patient, journal: Journal): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.journalCollectionRef.add(journal).then(docRef => {
        console.log('Document written with ID: ' + docRef.id);
        patient.journal.push(docRef.id);
        patient.lastVisit = journal.date;
        this.updatePatient(patient).then(() => {
          resolve(docRef.id);
        }).catch(err => {
          console.log(err);
          reject(err);
        });
      }).catch(err => {
        console.log('Could not write to database');
        console.log(err);
        reject(err);
      });
    });
  }

  /**
   * Add a new medical history document for the patient
   * @param {Patient} patient The patient that the document belongs to
   * @param {Journal} journal The new medical history document
   */
  addHistory(patient: Patient, journal: Journal): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.journalCollectionRef.add(journal).then(docRef => {
        console.log('Document written with ID: ' + docRef.id);
        patient.history.push(docRef.id);
        this.updatePatient(patient).then(() => {
          resolve(docRef.id);
        }).catch(err => {
          console.log(err);
          reject(err);
        });
      }).catch(err => {
        console.log('Could not write to database');
        console.log(err);
        reject(err);
      });
    });
  }

  /**
   * Add new consultation note for the patient
   * @param {Patient} patient The patient that the consultation note belongs to
   * @param {Consultation} consultation The consultation document
   * @returns {Promise} A promise that resolves to the newly added document id
   */
  addConsultation(patient: Patient, consultation: Consultation): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.consultationCollectionsRef.add(consultation).then(docRef => {
        console.log('Document written with ID: ' + docRef.id);
        if (patient.consultation) {
          patient.consultation.push(docRef.id);
        } else {
          patient.consultation = [docRef.id];
        }
        this.updatePatient(patient).then(() => {
          resolve(docRef.id);
        }).catch(err => {
          console.log(err);
          reject(err);
        });
      }).catch(err => {
        console.log('Could not write to database');
        console.log(err);
        reject(err);
      });
    });
  }

  /**
   * Get the user settings from the database
   */
  private getSettings(): void {
    this.settingsDocRef = this.fireStore.collection(this.user.uid).doc('settings');
    this.settings$ = this.settingsDocRef.valueChanges();
  }

  /**
   * Retrieve the list of predefined shortcuts
   * @returns {Observable<Settings>}
   */
  get settings(): Observable<Settings> {
    return this.settingsDocRef.valueChanges();
  }


  /**
   * Update the settings with new or changed properties
   * @param {Settings} settings The settings to update
   * @returns {Promise<string>}
   */
  updateSettings(settings: Settings): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const data = { ...settings };
      this.settingsDocRef.set(data).then(() => {
        console.log('Document with ID: ' + settings.id + ' updated.');
        resolve(settings.id);
      }).catch(err => {
        console.log('Could not write to database. Error: ', err);
        reject(err);
      });
    });
  }


}

