import { NgModule }                          from '@angular/core';
import { Routes, RouterModule }              from '@angular/router';
import { AuthGuard }                         from './auth.guard';
import { ElectronicPatientJournalComponent } from './electronic-patient-journal/electronic-patient-journal.component';
import { SettingsComponent }                 from './settings/settings.component';
import { PageNotFoundComponent }             from './page-not-found/page-not-found.component';
import { UserLoginComponent }                from './user-login/user-login.component';
import { PatientComponent }                  from './patient/patient.component';
import { NewJournalComponent }               from './new-journal/new-journal.component';
import { NewHistoryComponent }               from './new-history/new-history.component';
import { NewConsultationComponent }          from './new-consultation/new-consultation.component';
import { EulaComponent }                     from './eula/eula.component';
import { EulaGuard }                         from './eula.guard';
import { UpdateJournalComponent}             from './update-journal/update-journal.component';
import { CanDeactivateConsultationGuard,
         CanDeactivateGuard,
         CanDeactivateHistoryGuard }         from './can-deactivate.guard';
import { MobileGuard }                       from './mobile.guard';
import { MobileComponent }                   from './mobile/mobile.component';
import { LoginFailedComponent }              from './login-failed/login-failed.component';
import { AdminGuard }                        from './admin.guard';
import {StatisticsComponent} from './statistics/statistics.component';


const routes: Routes = [

  { path: 'login', component: UserLoginComponent },
  { path: 'login-failed', component: LoginFailedComponent },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canLoad: [ AdminGuard ]
  },
  {
    path: 'journal/:id',
    component: PatientComponent,
    data: { title: 'Journal' },
    canActivate: [ AuthGuard, EulaGuard ]
  },
  {
    path: 'new-journal/:id',
    component: NewJournalComponent,
    data: { title: 'Ny journal' },
    canActivate: [ AuthGuard, EulaGuard ],
    canDeactivate: [ CanDeactivateGuard ]
  },
  {
    path: 'update-journal',
    component: UpdateJournalComponent,
    data: { title: 'Oppdatér journal' },
    canActivate: [ AuthGuard, EulaGuard ]
  },
  {
    path: 'new-history/:id',
    component: NewHistoryComponent,
    data: { title: 'Ny anamnese' },
    canActivate: [ AuthGuard, EulaGuard ],
    canDeactivate: [ CanDeactivateHistoryGuard ]
  },
  {
    path: 'new-consultation/:id',
    component: NewConsultationComponent,
    data: { title: 'Nytt konsultasjons-notat' },
    canActivate: [ AuthGuard, EulaGuard ],
    canDeactivate: [ CanDeactivateConsultationGuard ]
  },
  {
    path: 'ejp',
    component: ElectronicPatientJournalComponent,
    data: { title: 'Pasienter' },
    canActivate: [ AuthGuard, EulaGuard, MobileGuard ]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'Innstillinger' },
    canActivate: [ AuthGuard, EulaGuard ]
  },
  {
    path: 'eula',
    component: EulaComponent,
    data: { title: 'Sluttbruker-avtale' },
    canActivateChild: [ AuthGuard ]
  },
  {
    path: 'mobile',
    component: MobileComponent,
    data: { title: 'Pasienter' },
    canActivate: [ AuthGuard, EulaGuard ]
  },
  {
    path: 'stats',
    component: StatisticsComponent,
    data: { title: 'Statistikk'},
    canActivate: [ AuthGuard, EulaGuard ]
  },
  {
    path: '',
    redirectTo: '/ejp',
    pathMatch: 'full',
    canActivate: [ AuthGuard, EulaGuard, MobileGuard ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
