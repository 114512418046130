import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of }  from 'rxjs';
import { switchMap }       from 'rxjs/operators';
import { environment }     from '../environments/environment';

export interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  eula?: boolean;
  admin?: boolean;
}

@Injectable()
export class AuthService {

  user: Observable<User>;

  private _uid: string;
  private _redirect: string;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router) {

    this._uid = null;
    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this._uid = user.uid;
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  set redirectURL(url: string) {
    this._redirect = url;
  }

  googleLogin() {
    const provider = new auth.GoogleAuthProvider();
    auth().useDeviceLanguage();
    return this.oAuthLogin(provider);
  }

  handleRedirect() {
    auth().getRedirectResult().then(result => {
      if (result && result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // ...
        console.log('Credentials:', result.credential);
      }
      // The signed-in user info.
      if (result && result.user) {
        console.log(result.user);
        this.updateUserData(result.user).then(() => {
          console.log('redirect', this._redirect);
          this.router.navigateByUrl(this._redirect).then(() => {
            console.log('Logged in');
          });
        });
      }
    }).catch(console.error);
  }

  private oAuthLogin(provider) {

    auth().setPersistence(auth.Auth.Persistence.SESSION).then(() => {
      auth().signInWithRedirect(provider).then(console.log);
    });
  }

  private updateUserData(user): Promise<any> {
    // Sets user data to firestore on login

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      eula: user.eula || false,
      admin: user.admin || false
    };

    return userRef.set(data, { merge: true });

  }

  acceptEULA() {
    return this.afs.doc(`users/${this._uid}`).update({eula: true});
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem(environment.authCookie);
      this.router.navigateByUrl('/login').then(() => {
        console.log('Navigated');
      });
    });
  }

}














