
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation } from '@angular/core';
import {
  Attachment,
  Journal,
  Patient,
  Shortcut }                              from '../journal';
import { ActivatedRoute, Router }         from '@angular/router';
import { FirebaseService, Upload }        from '../firebase.service';
import { Observable, Subscription }       from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { firestore } from 'firebase';
import { BusyService } from '../busy.service';

@Component({
  selector: 'app-update-journal',
  templateUrl: './update-journal.component.html',
  styleUrls: ['./update-journal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class UpdateJournalComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;
  private deletedAttachments: Attachment[] = [];
  private _journalId: string;
  private _patientId: string;

  public attachments: any[] = [];
  public lockJournal: boolean;

  patient$: Observable<Patient[]>;
  shortcut$: Observable<Shortcut[]>;

  public patient: Patient;
  public composing: boolean;
  public journal: Journal;
  public allShortcuts: Shortcut[] = [];
  public uploadProgress: number;
  public uploadIcon: string;
  public uploadColor: string;
  public date: Date = new Date();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private firebase$: FirebaseService,
              public dialog: MatDialog,
              public busy: BusyService) {

    this.composing = false;
    this.patient = null;
    this.journal = {} as Journal;
    this.journal.attachments = [];
    this.journal.shortcuts = [];
    this.patient$ = this.firebase$.patients;
    this.shortcut$ = this.firebase$.shortcuts;
    this._subscriptions = new Subscription();
    this.uploadProgress = 0;
    this.uploadIcon = 'save_alt';
    this.uploadColor = 'primary';
    this.lockJournal = true;
  }

  ngOnInit() {
    this._patientId = this.route.snapshot.paramMap.get('pid');
    this.firebase$.getPatient(this._patientId).then(p => this.patient = p );

    this._journalId = this.route.snapshot.paramMap.get('id');
    this.firebase$.getJournal(this._journalId).then(j => {
      this.journal = j;
      this.journal.id = this._journalId;
      this.allShortcuts.forEach(s => {
        if (this.journal.shortcuts.findIndex(x => x === s.id) !== -1) {
          s.selected = true;
        }
      });
      Promise.all(this.journal.attachments.map(a => this.firebase$.getAttachmentURL(this.patient, this.journal, a.name))).then(results => {
        this.journal.attachments.forEach((a, idx) => {
          a.url = results[idx];
          if (a.mimeType && a.mimeType.match(/application\/pdf/ig)) {
            a.icon = 'pdf';
          } else {
            a.icon = 'portrait';
          }
        });
      });
    });

    this._subscriptions.add(this.shortcut$.subscribe(shortcuts => {
      this.allShortcuts = shortcuts.map( s => ({selected: false, ...s}));
      if (this.journal) {
        this.allShortcuts.forEach(s => {
          if (this.journal.shortcuts.findIndex(x => x === s.id) !== -1) {
            s.selected = true;
          }
        });
      }
    }));

  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onFileChange(event) {
    for (let i = 0; i < event.target.files.length; ++i) {
      if (this.attachments.findIndex(x => x.file === event.target.files[i]) === -1) {
        const attachment = {
          file: event.target.files[i],
          name: event.target.files[i].name,
          type: event.target.files[i].type,
        url: null,
          icon: 'portrait'
        };

        if (attachment.type.match(/application\/pdf/ig)) {
          attachment.icon = 'pdf';
        }
        this.attachments.push(attachment);
      }
    }
  }

  showNewAttachment(a) {
    const url = window.URL.createObjectURL(a.file);
    window.open(url, '_blank');
  }

  showAttachment(a) {
    window.open(a.url, '_blank');
  }

  deleteNewAttachment(a: any) {
    const i = this.attachments.findIndex(x => x === a);
    if (i !== -1) {
      this.attachments.splice(i, 1);
    }
  }

  deleteAttachment(a: any) {

    let i = this.journal.attachments.findIndex(x => x === a);
    if (i !== -1) {
      this.deletedAttachments = [].concat(this.deletedAttachments, this.journal.attachments.splice(i, 1));
    }
    i = this.attachments.findIndex(x => x === a);
    if (i !== -1) {
      this.attachments.splice(i, 1);
    }
  }

  goHome(): void {
    this.router.navigate(['/ejp'], {skipLocationChange: true}).then(() => {
      console.log('route navigate complete');
    });
  }

  cancelUpdatedJournalEntry(): void {
    this.goHome();
  }

  storeUpdatedJournalEntry(): void {

    if (this.busy.working) {
      return;
    }

    const journal = {
      id: this._journalId,
      pid: this._patientId,
      locked: this.lockJournal,
      date: firestore.Timestamp.fromDate(this.date),
      entry: this.journal.entry,
      attachments: this.attachments.map(f => ({ name: f.name, mimeType: f.type } as Attachment)),
      shortcuts: this.allShortcuts.filter(x => x.selected).map(x => x.id)
    } as Journal;

    this.busy.working = true;

    journal.attachments = [].concat(this.journal.attachments, journal.attachments);
    this.deletedAttachments.forEach(a => {
      this.firebase$.deleteAttachment(this._patientId, this._journalId, a.name);
    });

    this.uploadIcon = 'cloud_upload';
    this.uploadProgress = 0;
    this.firebase$.updateJournal(journal).then(id => {
      const totalProgress = [];
      this.attachments.forEach((attachment, job) => {
        totalProgress.push(0);
        this.firebase$.pushUpload(new Upload(attachment.file, this._patientId, id)).subscribe(progress => {
          totalProgress[job] = progress;
          this.uploadProgress = totalProgress.reduce((total, x) => total + x);
          this.uploadProgress /= totalProgress.length;
          if (this.uploadProgress === 100) {
          }
        }, () => {
        }, () => {
          this.busy.working = false;
          this.uploadIcon = 'check';
          this.uploadColor = 'accent';
          setTimeout(() => {
            this.uploadIcon = 'save_alt';
            this.uploadColor = 'primary';
            this.journal.entry = '';
            this.journal.attachments = [];
            this.uploadProgress = 0;
            this.goHome();
          }, 2000);
        });
      });
      if (this.attachments.length === 0) {
        this.busy.working = false;
        this.journal.entry = '';
        this.journal.attachments = [];
        this.goHome();
      }
    });
  }

  getShortcuts(): Shortcut[] {
    return this.allShortcuts.filter(x => x.selected);
  }

  selectionChanged(event: MatSelectionListChange) {
    const s = this.allShortcuts.find(x => x.id === event.option.value);
    if (s) {
      s.selected = event.option.selected;
    }
  }

}
