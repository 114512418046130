import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {from, Observable, Subscription} from 'rxjs';
import {CardioVascularDisease, Consultation, Diabetic, GeneralPractitioner, Journal, Patient, Sex, Shortcut} from '../journal';
import {FirebaseService} from '../firebase.service';
import {PrintService} from '../print.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {NgForm} from '@angular/forms';
import {BusyService} from '../busy.service';
import {PostCodeCityService} from '../post-code-city.service';
import {firestore} from 'firebase';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class PatientComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;
  private _patientId: string;

  patient$: Observable<Patient[]>;
  shortcut$: Observable<Shortcut[]>;
  journal$: Observable<Journal[]>;

  public patient: Patient;
  public birthDate: Date;
  public journal: Journal;
  public journals: Journal[];
  public history: Journal[];
  public notes: Consultation[];
  public allShortcuts: Shortcut[] = [];
  public uploadProgress: number;
  public uploadIcon: string;
  public uploadColor: string;
  public tabIndex: number;
  public notEmpty = false;
  public editForm = false;
  public SexType = Sex;
  public DiabeticType = Diabetic;
  public CardioVascularDiseaseType = CardioVascularDisease;
  public hasDiabetes = false;
  public hasCVD = false;

  public gps: Observable<GeneralPractitioner[]>;
  public gp: Observable< GeneralPractitioner>;

  public isMobile: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb$: FirebaseService,
              private ps$: PrintService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private busy: BusyService,
              private zip: PostCodeCityService) {
    this.patient = null;
    this.journals = [];
    this.history = [];
    this.notes = [];
    this.birthDate = null;
    this.gps = new Observable<GeneralPractitioner[]>();
    this.gp = new Observable<GeneralPractitioner>();
    this.patient$ = this.fb$.patients;
    this.shortcut$ = this.fb$.shortcuts;
    this.journal$ = this.fb$.journals;
    this._subscriptions = new Subscription();
    this.uploadProgress = 0;
    this.uploadIcon = 'save_alt';
    this.uploadColor = 'primary';
    this.tabIndex = 0;
    this._patientId = '';

    this.isMobile = /Mobi|Android/i.test(navigator.userAgent);
  }

  ngOnInit() {
    setTimeout(() => this.busy.working = true);
    this._patientId = this.route.snapshot.paramMap.get('id');
    this.tabIndex = +this.route.snapshot.paramMap.get('tab') || 0;

    this._subscriptions.add(this.shortcut$.subscribe(shortcuts => {
      this.allShortcuts = [].concat(shortcuts);
    }));
    this._subscriptions.add(this.journal$.subscribe(() => {
      this.fb$.getPatient(this._patientId).then(p => {
        this.birthDate = p.birthDate.toDate();
        if (p.diabetic === this.DiabeticType.TypeI || p.diabetic === this.DiabeticType.TypeII) {
          this.hasDiabetes = true;
        }
        if (p.cvd === this.CardioVascularDiseaseType.Diagnosed) {
          this.hasCVD = true;
        }
        if (p.gp && p.gp !== 'none') {
          this.gp = from(this.fb$.getDoctor(p.gp));
        }
        this.getJournals(p);
        this.getHistory(p);
        this.getConsultations(p);
      }).catch(() => {
        this._subscriptions.unsubscribe();
      });
    }));
    this._subscriptions.add(this.patient$.subscribe(() => {
      this.fb$.getPatient(this._patientId).then(p => {
        this.getJournals(p);
        this.getHistory(p);
        this.getConsultations(p);
      }).catch(() => {
        this._subscriptions.unsubscribe();
      });
    }));
  }

  findCityFromZip(form: NgForm) {

    if (this.editForm) {
      const code = form.value.address.code;
      if (code) {
        const city = this.zip.find(code);
        form.value.address.city = city;
        this.patient.address.city = city;

        const municipality = this.zip.municipality(code);
        if (municipality) {
          this.gps = this.fb$.doctorSearch(municipality);
        }
      }
    }
  }

  showAttachment(a) {
    window.open(a.url, '_blank');
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  getShortcuts(journal: Journal): Shortcut[] {
    return this.allShortcuts.filter(x => journal.shortcuts.find(y => y === x.id));
  }

  journalByDate(): Journal[] {
    return this.journals.sort((a, b) => a.date < b.date ? 1 : a.date === b.date ? 0 : -1);
  }

  historyByDate(): Journal[] {
    return this.history.sort((a, b) => a.date < b.date ? 1 : a.date === b.date ? 0 : -1);
  }

  notesByDate(): Consultation[] {
    return this.notes.sort((a, b) => a.date < b.date ? 1 : a.date === b.date ? 0 : -1);
  }

  newJournal(): void {
    this.router.navigate(['/new-journal', this.patient.id], {skipLocationChange: true}).then(() => {
      console.log('route navigate complete');
    });
  }

  newHistory(): void {
    this.router.navigate(['/new-history', this.patient.id], {skipLocationChange: true}).then(() => {
      console.log('route navigate complete');
    });
  }

  newConsultation(): void {
    console.log('Create new consultation note');
    this.router.navigate(['/new-consultation', this.patient.id], {skipLocationChange: true}).then(() => {
      console.log('route navigate complete');
    });
  }

  printJournal(j: Journal) {
    this.ps$.createDocumentFromJournal(this.patient, j);
  }

  printNote(c: Consultation) {
    this.ps$.createDocumentFromConsultation(this.patient, c);
  }

  editJournal(j: Journal) {
    this.router.navigate(['/update-journal', {id: j.id, pid: this.patient.id}], {skipLocationChange: true}).then(() => {
      console.log('navigated to /update-journal');
    });
  }

  getJournals(p: Patient): void {
    Promise.all(p.journal.map(id => this.fb$.getJournal(id))).then(journals => {
      journals.forEach(journal => {
        Promise.all(journal.attachments.map(a => this.fb$.getAttachmentURL(p, journal, a.name))).then(results => {
          journal.attachments.forEach((a, idx) => {
            a.url = results[idx];
            if (a.mimeType && a.mimeType.match(/application\/pdf/ig)) {
              a.icon = 'pdf';
            } else {
              a.icon = 'portrait';
            }
          });
        });
      });
      this.journals = [].concat(journals);
      this.patient = p;
      if (this.patient.lastVisit.seconds === 0) {
        /* last visit not added - scan journals and use the timestamp from the latest visit */
        let last = new firestore.Timestamp(0, 0);
        this.journals.forEach(j => {
          if (last < j.date) {
            last = j.date;
          }
        });
        this.patient.lastVisit = last;
        this.fb$.updatePatient(this.patient).then(() => console.log('Added lastVisit to patient'));
      }
      setTimeout(() => this.busy.working = false);
    }).catch(err => {
      console.log('Could not get patient and/or journals');
      console.log(err);
    });
  }

  getHistory(p: Patient): void {
    Promise.all(p.history.map(id => this.fb$.getJournal(id))).then(history => {
      history.forEach(journal => {
        Promise.all(journal.attachments.map(a => this.fb$.getAttachmentURL(p, journal, a.name))).then(results => {
          journal.attachments.forEach((a, idx) => {
            a.url = results[idx];
            if (a.mimeType && a.mimeType.match(/application\/pdf/ig)) {
              a.icon = 'pdf';
            } else {
              a.icon = 'portrait';
            }
          });
        });
      });
      this.history = [].concat(history);
    }).catch(err => {
      console.log('Could not get patient and/or journals');
      console.log(err);
    });
  }

  getConsultations(p: Patient): void {
    if (p.consultation) {
      Promise.all(p.consultation.map(id => this.fb$.getConsultation(id))).then(consultations => {
        this.notes = [].concat(consultations);
      }).catch(err => {
        console.log('Could not get patient and/or journals');
        console.log(err);
      });
    } else {
      this.notes = [];
    }
  }

  changed(data: string): void {
    this.notEmpty = (data && data.length > 0);
  }

  validateForm(form: NgForm) {
    if (this.editForm) {
      const municipality = this.zip.municipality(form.value.address.code);
      if (municipality) {
        this.gps = this.fb$.doctorSearch(municipality);
      }
    }
    if (!this.editForm && form.dirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '350px',
        data: { ok: 'warn', title: 'Lagre endringer?', message: `Du har gjort endringer uten å lagre. Lagre endringer?`}
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.onSubmit(form);
        } else {
          form.resetForm(this.patient);
        }
      });
    }
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.patient.birthDate = firestore.Timestamp.fromDate(form.value.birthDate);
      this.patient.sex = form.value.sex;
      this.patient.diabetic = form.value.diabetic;
      this.patient.cvd = form.value.cvd;
      this.patient.name.first = form.value.name.first;
      this.patient.name.middle = form.value.name.middle;
      this.patient.name.last = form.value.name.last;
      this.patient.address.street = form.value.address.street;
      this.patient.address.city = form.value.address.city;
      this.patient.address.code = form.value.address.code;
      this.patient.phone = form.value.phone;
      this.patient.mobile = form.value.mobile;
      this.patient.email = form.value.email;
      if (this.patient.gp !== form.value.doctor) {
        this.gp = from(this.fb$.getDoctor(form.value.doctor));
      }
      this.patient.gp = form.value.doctor;

      this.fb$.updatePatient(this.patient).then(() => {
        this.hasCVD = this.patient.cvd === CardioVascularDisease.Diagnosed;
        this.hasDiabetes = (this.patient.diabetic === Diabetic.TypeI) || (this.patient.diabetic === Diabetic.TypeII);
        form.resetForm(this.patient);
        this.birthDate = this.patient.birthDate.toDate();
      });
      this.editForm = false;
    }
  }

  terminate(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {ok: 'warn', title: 'Slett pasient?', message: `Er du sikker på at du vil slette ${this.patient.name.first} fra registeret?`}
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        setTimeout(() => this.busy.working = true);
        this.fb$.deletePatient(this.patient).then(deleted => {
          setTimeout(() => this.busy.working = false);
          this.router.navigate(['/ejp'], {skipLocationChange: true}).then(() => {
            console.log('route navigate complete');
          });
          if (deleted) {
            this.snackBar.open('Pasienten ble fjernet fra registeret', 'OK!', {
              duration: 2000,
            });
          } else {
            this.snackBar.open('Pasienten ble ikke fjernet fra registeret', 'OK!', {
              duration: 2000,
            });
          }
        });
      }
    });

  }

  saveChanges(form: NgForm): void {
    this.onSubmit(form);
  }

  cancelChanges(form: NgForm): void {
    form.resetForm(this.patient);
    this.birthDate = this.patient.birthDate.toDate();
    this.editForm = false;
  }


}
