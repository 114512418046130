import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FirebaseService, UploadLogo } from '../firebase.service';
import { Attachment, Settings, Shortcut} from '../journal';
import { Observable }          from 'rxjs';
import { Subscription }        from 'rxjs/internal/Subscription';
import { FormBuilder, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

interface BrRegEnhet {
  navn: string;
  hjemmeside: string;
  forretningsadresse: {
    adresse: [string],
    poststed: string,
    postnummer: string
  };
}

class SettingsImpl implements Settings {
  id?: string;
  header: {
    left: string[];
    center: string[];
    right: string[];
  };
  footer: {
    left: string[];
    center: string[];
    right: string[];
  };
  company: {
    name: string;
    address: {
      street: string;
      city: string;
      code: string;
    };
    phone: string;
    mobile: string;
    email: string;
    web: string;
    org: string;
    logo?: Attachment;
  };

  constructor() {
    this.header = { left: [], center: [], right: [] };
    this.footer = { left: [], center: [], right: [] };
    this.id = '';
    this.company = {
      name: '',
      address: {
        street: '',
        city: '',
        code: ''
      },
      phone: '',
      mobile: '',
      email: '',
      web: '',
      org: '',
      logo: {id: '', name: '', url: '' } as Attachment
    };
  }
}


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SettingsComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  shortName: string;
  shortcut: string;
  shortcuts: Observable<Shortcut[]>;
  selected: Shortcut;
  settings: Settings;
  icon: string;
  progress: number;
  busy: boolean;
  uploadIcon: string;
  uploadColor: string;
  logoFile: File;
  logoUrl: string;
  topLogo: number;
  bottomLogo: number;

  headerAndFooterForm = this.fb.group({
    header: this.fb.group({
      left: [''],
      center: [''],
      right: [''],
      logo: 0
    }),
    footer: this.fb.group({
      left: [''],
      center: [''],
      right: [''],
      logo: 0
    })
  });

  companyForm = this.fb.group({
    name: ['', Validators.required ],
    address: this.fb.group({
      street: [''],
      city: [''],
      code: ['']
    }),
    phone: [''],
    mobile: [''],
    web: [''],
    email: [''],
    org: ['', Validators.required],
    logo: this.fb.group({
      name: [''],
      url: [''],
      id: ['']
    })
  });

  constructor(private fb$: FirebaseService, private fb: FormBuilder, public snackBar: MatSnackBar, private http: HttpClient,
              public dialog: MatDialog) {
    this.subscription = new Subscription();
    this.shortcuts = this.fb$.shortcuts;
    this.selected = null;
    this.icon = 'add';
    this.settings = new SettingsImpl();
    this.uploadIcon = 'cloud_upload';
    this.uploadColor = 'primary';
    this.logoFile = null;
    this.topLogo = 0;
    this.bottomLogo = 0;
  }

  ngOnInit() {
    console.log('Subscribing to settings');

    this.subscription.add(this.fb$.settings.subscribe(value => {
      console.log('Settings retrieved');
      this.settings = value as Settings || new SettingsImpl();
      if (this.settings) {

        if (this.settings.header) {
          this.resetHeaderAndFooter();
        }
        if (this.settings.company) {
          this.resetCompany();
        }
      }
    }));
  }

  ngOnDestroy() {
    console.log('Unsubscribe');
    this.subscription.unsubscribe();
  }


  /**
   * Look up company id in the public records
   */
  searchByOrg () {

    const org = this.companyForm.value.org;
    this.http.get(`https://data.brreg.no/enhetsregisteret/api/enheter/${org}`).subscribe((result: BrRegEnhet) => {

      this.companyForm.patchValue({
        name: result.navn,
        address: {
          street: result.forretningsadresse.adresse[0],
          code: result.forretningsadresse.postnummer,
          city: result.forretningsadresse.poststed
        },
        web: result.hjemmeside
      });
      this.companyForm.markAsDirty();
    }, () => {
      console.log('ERROR');
      this.dialog.open(InfoDialogComponent, {
        width: '350px',
        data: { title: 'Hmmm....', message: 'Kunne ikke henta data for dette org. nr.' }
      });
    });
  }

  private resetCompany() {
    this.companyForm.setValue({
      name: this.settings.company.name,
      address: {
        street: this.settings.company.address.street,
        code: this.settings.company.address.code,
        city: this.settings.company.address.city
      },
      phone: this.settings.company.phone,
      mobile: this.settings.company.mobile,
      email: this.settings.company.email,
      web: this.settings.company.web,
      org: this.settings.company.org,
      logo: {
        id: this.settings.company.logo ? this.settings.company.logo.id : '',
        name: this.settings.company.logo ? this.settings.company.logo.name : 'Ingen logo valgt',
        url: this.settings.company.logo ? this.settings.company.logo.url : ''
      }
    });
    this.logoUrl = this.settings.company.logo ? this.settings.company.logo.url : '';
    // console.log(this.logoUrl);

  }

  addOrUpdateShortcut(): void {
    if (this.selected !== null && this.selected.id) {

    } else {

      this.fb$.addShortcut({name: this.shortName, text: this.shortcut} as Shortcut).then(shortcut => {
        console.log('Shortcut with id ' + shortcut.id + ' added.');
      }).catch(e => {
        console.log('Could not add shortcut. Error:', e);
      });
      this.shortName = '';
      this.shortcut = '';
    }
  }

  showShortcut(s: Shortcut): void {
    this.shortName = s.name;
    this.shortcut = s.text;
    this.selected = s;
    this.icon = 'mode_edit';
  }

  delete(s: Shortcut): void {
    console.dir(this.selected);
    this.fb$.deleteShortcut(s).then(() => {
      if (this.selected === s) {
        this.shortcut = '';
        this.shortName = '';
        this.selected = null;
      }
    });
  }

  usage(s: Shortcut): boolean {
    return false;
  }

  cancelCompanyChanges(): void {
    console.log('cancelling changes');
    this.resetCompany();
  }

  onCompanySubmit(): void {
    console.log('company submit');
    this.settings.company = Object.assign({}, this.companyForm.value);
    if (this.logoFile) {
      this.uploadCompanyLogo(this.logoFile).then(url => {
        this.settings.company.logo.url = url;
        this.logoUrl = url;
        console.log(this.logoUrl);
        console.log(this.settings.company);
        this.fb$.updateSettings(this.settings as Settings).then(() => {
          this.companyForm.reset(this.settings.company);
          this.snackBar.open('Endringene ble lagret', 'OK!', {
            duration: 2000,
          });
        });
      });
    } else {
      this.fb$.updateSettings(this.settings as Settings).then(() => {
        this.companyForm.reset(this.settings.company);
        this.snackBar.open('Endringene ble lagret', 'OK!', {
          duration: 2000,
        });
      });
    }
  }

  onFileChange(event) {
    if (event.target.files.length) {
      this.logoFile = event.target.files[0];
      this.companyForm.controls['logo'].patchValue({ name: this.logoFile.name }, {onlySelf: false, emitEvent: false } );
      this.companyForm.controls['logo'].markAsDirty();
      console.log(this.companyForm);

      // this.uploadCompanyLogo(event.target.files[0]);

      const reader = new FileReader();

      // Closure to capture the file information.
      reader.onload = ((f) => {
        return e => {
          this.logoUrl = e.target.result;
        };
      })(this.logoFile);

      // Read in the image file as a data URL.
      reader.readAsDataURL(this.logoFile);
    }

  }

  uploadCompanyLogo(file: File): Promise<string> {
    this.busy = true;
    this.progress = 0;
    return new Promise<string>((resolve) => {
      const upload = new UploadLogo(file, this.settings.id);
      this.fb$.uploadLogo(upload).subscribe(progress => {
        this.progress = progress;
      }, () => {
      }, () => {
        this.busy = false;
        this.progress = 0;
        resolve(upload.url);
      });
    });
  }

  private resetHeaderAndFooter() {
    this.headerAndFooterForm.setValue({
      header: {
        left: this.settings.header.left.join('\n'),
        center: this.settings.header.center.join('\n'),
        right: this.settings.header.right.join('\n'),
        logo: this.settings.header.logo || 0
      },
      footer: {
        left: this.settings.footer.left.join('\n'),
        center: this.settings.footer.center.join('\n'),
        right: this.settings.footer.right.join('\n'),
        logo: this.settings.footer.logo || 0
      }
    });
    this.bottomLogo = this.settings.footer.logo || 0;
    this.topLogo = this.settings.header.logo || 0;
  }

  cancelHeaderAndFooterChanges(): void {
    this.resetHeaderAndFooter();
  }

  onHeaderAndFooterSubmit(): void {
    this.settings.header.left = this.headerAndFooterForm.value.header.left.split('\n');
    this.settings.header.center = this.headerAndFooterForm.value.header.center.split('\n');
    this.settings.header.right = this.headerAndFooterForm.value.header.right.split('\n');
    this.settings.footer.left = this.headerAndFooterForm.value.footer.left.split('\n');
    this.settings.header.logo = this.topLogo;
    this.settings.footer.center = this.headerAndFooterForm.value.footer.center.split('\n');
    this.settings.footer.right = this.headerAndFooterForm.value.footer.right.split('\n');
    this.settings.footer.logo = this.bottomLogo;
    this.fb$.updateSettings(this.settings as Settings).then(() => {
      this.headerAndFooterForm.reset();
      this.resetHeaderAndFooter();
      this.snackBar.open('Endringene ble lagret', 'OK!', {
        duration: 2000,
      });
    });
  }

}
