import { Injectable }               from '@angular/core';
import { CanDeactivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot,
         UrlTree }                  from '@angular/router';
import { Observable }               from 'rxjs';
import { NewJournalComponent  }     from './new-journal/new-journal.component';
import { NewHistoryComponent  }     from './new-history/new-history.component';
import { NewConsultationComponent } from './new-consultation/new-consultation.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<NewJournalComponent> {

  canDeactivate(
    component: NewJournalComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.canDeactivate();
  }

}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateHistoryGuard implements CanDeactivate<NewHistoryComponent> {

  canDeactivate(
    component: NewHistoryComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.canDeactivate();
  }

}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateConsultationGuard implements CanDeactivate<NewConsultationComponent> {

  canDeactivate(
    component: NewConsultationComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.canDeactivate();
  }

}

