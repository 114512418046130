import { MatPaginatorIntl } from '@angular/material/paginator';

export class MatPaginatorIntlNO extends MatPaginatorIntl {
  itemsPerPageLabel = 'Pasienter per side';
  nextPageLabel     = 'Neste';
  previousPageLabel = 'Forrige';

  getRangeLabel = (page, pageSize, length): string => {
    if (length === 0 || pageSize === 0) {
      return '0 av ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' / ' + length;
  }
}
