import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BusyService {

  private _working: boolean;

  constructor() {
    this._working = false;
  }

  set working(b: boolean) {
    setTimeout(() => this._working = b);
  }

  get working() {
    return this._working;
  }
}
