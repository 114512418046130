import { Component, OnInit } from '@angular/core';
import { AuthService }       from '../auth.service';
import { environment }       from '../../environments/environment';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

  public pending: boolean;

  constructor(public auth: AuthService) {
    this.pending = false;
  }

  ngOnInit() {
    this.auth.redirectURL = '/ejp';
    this.auth.handleRedirect();
    const item = localStorage.getItem(environment.authCookie);
    if (item) {
      this.pending = true;
    }
  }

  google() {
    console.log('login with google');
    const now = new Date();
    localStorage.setItem(environment.authCookie, now.toISOString());
    this.auth.googleLogin();
  }

  cancel() {
    localStorage.removeItem(environment.authCookie);
    this.pending = false;
  }

}
