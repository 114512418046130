import { MarkedRenderer } from 'ngx-markdown';

export class MarkdownRenderer extends MarkedRenderer {

  text(text: string): string {
    return `"${text}",`;
  }

  strong(text: string): string {
    return `{"text": ${text}, "bold": true, "style": "body2"},`;
  }

  em(text: string): string {
    return `{"text": ${text}, "italics": true, "style": "body1"},`;
  }

  paragraph(text: string): string {
    return `{"text": [${text}], "style": "body1"},`;
  }

  heading(text: string, level: 1 | 2 | 3 | 4 | 5 | 6, raw: string, slugger: marked.Slugger): string {
    let style;
    switch (level) {
      case 1: style = 'display4'; break;
      case 2: style = 'display3'; break;
      case 3: style = 'display2'; break;
      case 4: style = 'display1'; break;
      case 5: style = 'headline'; break;
      case 6: style = 'title'; break;
    }
    return `{"text": [${text}], "style": "${style}"},`;
  }

  listitem(text: string): string {
    return `{"text": [${text}], "style": "body1"},`;
  }

  list(body: string, ordered: boolean, start: number): string {
    if (ordered) {
      if (start) {
        return `{"start": ${start}, "ol": [ ${body} ]},`;
      }
      return `{"ol": [ ${body} ]},`;
    }
    return `{"ul": [ ${body} ]},`;
  }

  private cleanup(txt: string) {
    txt = txt.replace (/,+/g, ',');
    txt = txt.replace(/,\s*\]/g, ']');
    txt = txt.replace(/\},$/, '}');
    txt = '{"stack": [' + txt + ']}';
    return txt;
  }

  getContents(parsed: string): any {
    parsed = this.cleanup(parsed);
    try {
      return JSON.parse(parsed);
    } catch (e) {
      console.log(e);
      console.log(parsed);
    }
    return null;
  }

}
