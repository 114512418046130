import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgePipe, CardioVascularDisease, Diabetic, Patient, Sex } from '../journal';
import { FirebaseService } from '../firebase.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, OnDestroy {

  private patients: Patient[] = [];
  private _subscription: Subscription;

  public ready  = false;
  public males: number;
  public males100: number;
  public females: number;
  public females100: number;
  public diabetes: number;
  public diabetes100: number;
  public cvd: number;
  public cvd100: number;
  public youngest: number;
  public oldest: number;
  public average: number;
  public count: number;

  constructor(private firebase$: FirebaseService, private agePipe: AgePipe) {
    this.males = 0;
    this.females = 0;
    this.diabetes = 0;
    this.diabetes100 = 0;
    this.cvd = 0;
    this.cvd100 = 0;
    this.youngest = 999;
    this.oldest = 0;
    this.average = 0;
    this.count = 0;
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    this.firebase$.available.subscribe(available => {
      if (available) {
        this._subscription.add(this.firebase$.patients.subscribe(patients => {
          if (patients) {
            this.patients = patients;
            this.compute();
          }
        }));
      }
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  compute(): void {
    this.count = this.patients.length;
    this.patients.forEach(p => {
      if (p.sex === Sex.Male) {
        this.males++;
      } else if (p.sex === Sex.Female) {
        this.females++;
      }
      if (p.diabetic === Diabetic.TypeI || p.diabetic === Diabetic.TypeII) {
        this.diabetes++;
      }
      if (p.cvd === CardioVascularDisease.Diagnosed) {
        this.cvd++;
      }
      const age = this.agePipe.transform(p.birthDate.toDate());
      this.youngest = Math.min(age, this.youngest);
      this.oldest = Math.max(age, this.oldest);
      this.average += age;
    });
    this.males100 = +((this.males / this.patients.length) * 100.0).toFixed(0);
    this.females100 = +((this.females / this.patients.length) * 100.0).toFixed(0);
    this.average = +(this.average / this.patients.length).toFixed(0);
    this.diabetes100 = +((this.diabetes / this.patients.length) * 100.0).toFixed(0);
    this.cvd100 = +((this.cvd / this.patients.length) * 100.0).toFixed(0);
    this.ready = true;
  }

}
