import { Component, OnInit }            from '@angular/core';
import { Attachment, Journal, Patient } from '../journal';
import { Router }                       from '@angular/router';
import { FirebaseService, Upload }      from '../firebase.service';
import { BusyService }                  from '../busy.service';
import { MatDialog } from '@angular/material/dialog';
import { environment }                  from '../../environments/environment';
import { Observable }                   from 'rxjs';
import { InfoDialogComponent }          from '../info-dialog/info-dialog.component';

class IncompleteJournal {
  public attachments: any[];
  constructor(public patient: Patient, public journal: Journal) {
    this.attachments = [];
  }
}

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {

  incompleteTable: IncompleteJournal[];
  public uploadProgress: number;
  public hasOpen: Observable<boolean>;

  constructor(private router: Router, private fb$: FirebaseService, public busy: BusyService,
              public dialog: MatDialog) {
    this.busy.working = true;
    this.uploadProgress = 0;
    this.hasOpen = this.fb$.hasOpenJournals();
  }

  ngOnInit() {

    this.busy.working = true;

    this.incompleteTable = [];

    this.fb$.available.subscribe(available => {
      if (available) {
        this.busy.working = false;
        this.fb$.unlockedJournals().subscribe((incomplete: Journal[]) => {
          Promise.all(incomplete.map((j: Journal) => this.fb$.getPatient(j.pid))).then(results => {
            this.incompleteTable = results.map((p, i) => {
              return new IncompleteJournal(p, incomplete[i]);
            });
          });
        });
      }
    });

    /* On successful login delete the cookie token */
    localStorage.removeItem(environment.authCookie);
  }
  onFileChange(entry: IncompleteJournal, event) {
    for (let i = 0; i < event.target.files.length; ++i) {
      if (entry.attachments.findIndex(x => x.file === event.target.files[i]) === -1) {
        const attachment = {
          file: event.target.files[i],
          name: event.target.files[i].name,
          type: event.target.files[i].type,
          url: null,
          icon: 'portrait'
        };

        if (attachment.type.match(/application\/pdf/ig)) {
          attachment.icon = 'pdf';
        }
        entry.attachments.push(attachment);
      }
    }
  }

  deleteAttachment(entry: IncompleteJournal, a: any) {

    const i = entry.attachments.findIndex(x => x === a);
    if (i !== -1) {
      entry.attachments.splice(i, 1);
    }
  }

  showAttachment(a: any) {
    const url = window.URL.createObjectURL(a.file);
    window.open(url, '_blank');
  }

  storeUpdatedJournalEntry(entry: IncompleteJournal): void {

    if (this.busy.working) {
      return;
    }

    entry.journal.locked = true;
    entry.journal.attachments = entry.attachments.map(f => ({ name: f.name, mimeType: f.type } as Attachment));

    this.busy.working = true;

    this.uploadProgress = 0;
    this.fb$.updateJournal(entry.journal).then(id => {
      const totalProgress = [];
      entry.attachments.forEach((attachment, job) => {
        totalProgress.push(0);
        this.fb$.pushUpload(new Upload(attachment.file, entry.patient.id, id)).subscribe(progress => {
          totalProgress[job] = progress;
          this.uploadProgress = totalProgress.reduce((total, x) => total + x);
          this.uploadProgress /= totalProgress.length;
        }, () => {
          this.dialog.open(InfoDialogComponent, {
            width: '350px',
            data: { title: 'En feil oppstod...', message: 'Kunne ikke fullføre opplasting av vedlegg.' }
          });
        }, () => {
          setTimeout(() => {
            this.busy.working = false;
            this.uploadProgress = 0;
          }, 2000);
        });
      });
    });
  }

}
