import {
  Component,
  OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import { NgForm }     from '@angular/forms';
import {
  Consultation,
  Patient }                               from '../journal';
import { ActivatedRoute, Router }         from '@angular/router';
import { FirebaseService }                from '../firebase.service';
import { Observable, Subscription }       from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { firestore }                      from 'firebase';
import { ConfirmDialogComponent }         from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-new-consultation',
  templateUrl: './new-consultation.component.html',
  styleUrls: ['./new-consultation.component.scss']
})
export class NewConsultationComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;
  private busy: boolean;

  public attachments: File[] = [];

  @ViewChild(NgForm) form: NgForm;

  patient$: Observable<Patient[]>;

  public patient: Patient;
  public composing: boolean;
  public consultation: Consultation;
  public uploadProgress: number;
  public uploadIcon: string;
  public uploadColor: string;
  public date: Date = new Date();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private firebase$: FirebaseService,
              public dialog: MatDialog) {
    this.composing = false;
    this.patient = null;
    this.consultation = {} as Consultation;
    this.patient$ = this.firebase$.patients;
    this._subscriptions = new Subscription();
    this.uploadProgress = 0;
    this.busy = false;
    this.uploadIcon = 'save_alt';
    this.uploadColor = 'primary';
  }

  ngOnInit() {
    this.firebase$.getPatient(this.route.snapshot.paramMap.get('id')).then(p => {
      this.patient = p;
      this.newConsultationNote();
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form.pristine) {
      return true;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: { ok: 'warn', title: 'Lagre endringer?', message: `Du har gjort endringer uten å lagre. Forkast endringer?` }
    });

    return dialogRef.afterClosed();
  }

  cancelNewConsultationNote(): void {
    this.goToJournal();
  }

  storeNewConsultationNote(): void {

    if (this.busy) {
      return;
    }

    const note = {
      date: firestore.Timestamp.fromDate(this.date),
      note: this.consultation.note
    } as Consultation;

    this.busy = true;
    this.uploadIcon = 'cloud_upload';
    this.uploadProgress = 0;
    this.firebase$.addConsultation(this.patient, note).then(() => {
        this.busy = false;
        this.consultation.note = '';
        this.uploadProgress = 100;
        this.form.resetForm();
        this.goToJournal();
    });
  }

  /**
   * Add new journal entry
   */
  newConsultationNote(): void {
    this.attachments = [];
    this.consultation.date = firestore.Timestamp.now();
    this.consultation.note = '';
  }

  goToJournal(): void {
    this.router.navigate(['/journal', this.patient.id, { tab: '2' }], {skipLocationChange: true}).then(() => {
      console.log('route navigate complete');
    });
  }

}

