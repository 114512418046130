import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger} from '@angular/animations';
import { AnimationEvent } from '@angular/animations';

@Component({
  selector: 'app-icon-morph',
  animations: [
    trigger('morph', [
      state('init', style({ transform: 'rotate(0deg) scale(1)' })),
      state('start', style({  transform: 'rotate(359deg) scale(0.2)' })),
      state('finish', style({  transform: 'rotate(720deg) scale(1.0)' })),
      transition('init => start', animate('500ms ease-in-out')),
      transition('start => finish', animate('500ms ease-out'))
    ]),
  ],
  templateUrl: './icon-morph.component.html',
  styleUrls: ['./icon-morph.component.scss']
})
export class IconMorphComponent implements OnInit {

  @Input() from: string;
  @Input() to: string;

  icon: string;
  state: string;

  constructor() { }

  ngOnInit() {
    this.icon = this.from;
    this.state = 'init';
  }

  onAnimationEvent ( event: AnimationEvent ) {
    // openClose is trigger name in this example
    if (event.triggerName === 'morph') {
      if (event.toState === 'start') {
        this.icon = this.to;
        this.state = 'finish';
      } else if (event.toState === 'finish') {
        const i = this.from;
        this.from = this.to;
        this.to = i;
      }
    }
  }
}
