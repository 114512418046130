import { Injectable } from '@angular/core';
import * as postalCodes from './postnummer.json';
import * as municipalities from './kommuner.json';

export interface PostCode {
  result: string;
  valid: boolean;
  postalCodeType: string;
}

@Injectable({
  providedIn: 'root'
})
export class PostCodeCityService {

  pnr: any  = (postalCodes  as  any).default;
  mnr: any = (municipalities as any).default;

  constructor() { }

  /**
   * Based on the postal code, find the city name
   * @param {string} code A string containing 4 digits
   * @returns {string} The city name or an empty string if not found
   */
  find(code: string) {
    if (!!this.pnr[code]) {
      return this.pnr[code].city;
    }
    return '';
  }

  /**
   * Get the municipality number from the postal code
   * @param {string} code The postal code
   * @returns {string} The municipality number or an empty string if not found
   */
  municipality(code: string) {
    if (!!this.pnr[code]) {
      return this.pnr[code].municipality;
    }
    return '';
  }

  /**
   * From the municipality code, return its name
   * @param {string} code A four digit code as a string
   * @returns {string} The name or an empty string if the code wasn't found
   */
  municipalityName(code: string) {
    return this.mnr[code] || '';
  }

}
