import { Component, OnInit } from '@angular/core';
import { Router }            from '@angular/router';

@Component({
  selector: 'app-markdown-help',
  templateUrl: './markdown-help.component.html',
  styleUrls: ['./markdown-help.component.scss']
})
export class MarkdownHelpComponent implements OnInit {

  public markdown = `# Overskrift H1
## Overskrift H2
### Overskrift H3
#### Overskrift H4
##### Overskrift H5
###### Overskrift H6

_Kursiv_, eller *kursiv*

**Fete typer**

**_Fete typer i kursiv_**

~~Stryk dette~~

1. Nummerliste
  1. Nummerliste
  1. Nummerliste
0. Nummer-liste
  0. Trenger bare et tall
    1. Kan også ha flere nivåer

* Punkt-liste
  * Kan ha to
    * Eller flere nivåer

      Bruk innrykk for å plassere tekst til et punkt
`;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  close(): void {
    this.router.navigate([{outlets: {popup: null}}]).then(result => {
      console.dir(result);
    });
  }
}
